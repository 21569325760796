<template>
    <div class="flex justify-center">
        <form class="flex flex-col w-5/12" @submit.prevent="signIn({username, password}, { callbackUrl: '/', external: true })">
          <label class="font-bold text-sm" for="username">Username</label>
          <input id="username" v-model="username" class="rounded-md border border-[#95989A] p-2 mb-3" type="text">

          <label class="font-bold text-sm" for="password">Password</label>
          <input id="password" v-model="password" class="rounded-md border border-[#95989A] p-2 mb-3" type="password">
          
          <button class="rounded-md text-white bg-[#5fbb3a] p-2">Login</button>
        </form>
    </div>
</template>

<script setup lang="ts">
const { signIn } = useAuth();

const username = ref('');
const password = ref('');

definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/'
  }
})
</script>